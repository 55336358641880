import React from 'react';
import { createRoot } from "react-dom/client";
import { DateTime } from "luxon";


function make_id (length) {
    let result             = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

class Timestamp extends React.Component {
    render() {
        const dt = DateTime.fromMillis(this.props.stamp);
        return <em>{dt.toRelative()}</em>;
    }
}

String.prototype.nl2br = function () {
    return this.replaceAll("\n","<br />")
}

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            post: null,
            guest_id: 'guest_' + make_id(6)
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onPaste = this.onPaste.bind(this);

        const url = new URL(window.location)

        url.protocol = window.location.protocol.substr(0,5) == 'https' ? 'wss' : 'ws';
        url.pathname = "/ws"
        this.ws = new WebSocket(url);
        if (!this.ws) {
            throw new Error("server didn't accept ws")
        }
        this.ws.addEventListener("message", ({ data }) => {
            this.setState({
                posts: [...this.state.posts, JSON.parse(data)]
            });
        });

        this.interval = null;
    }

    async componentDidMount() {
        const posts = await this.getPosts();
        this.setState(prevState =>{
            return { posts: posts }
        })
    }

    async getPosts() {
        const response = await fetch("/api/chat", {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        });
        return response.json();
    }

    async savePost(post) {
        return this.ws.send(JSON.stringify(post));
        // return await fetch("/api/chat", {
        //     method: 'POST',
        //     body: JSON.stringify(post),
        //     headers: {'Content-Type': 'application/json'},
        // });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const scrollingElement = document.getElementsByClassName('is-middle')[0];
        scrollingElement.scrollTop = scrollingElement.scrollHeight;

        window.clearInterval(this.interval);
        this.interval = window.setInterval(this.forceUpdate.bind(this), 1000 * 15)
    }

    onChange (e) {
        e.stopPropagation();
        e.preventDefault();

        if (e.target.value.trim() == "") {
            return false;
        }

        this.setState({ post: {
            message: e.target.value,
            author: this.state.guest_id,
            timestamp: Date.now()
        }});
    }

    onPaste (e) {
        // Give call stack time to finish
        // the paste before we read the value.
        // Otherwise, we need to write all paste handling logic
        setTimeout(this.onChange.bind(this, e))
    }

    async onSubmit (e) {
        e.stopPropagation();
        e.preventDefault();

        if ( !this.state.post ) {
            return false;
        }

        this.state.post.timestamp = Date.now();
        const res = await this.savePost(this.state.post);


        if ( !res || res.status == 201 ) {
            this.setState({
                //posts: [...this.state.posts, this.state.post],
                post: null
            });

            e.target.reset();
        }
        else {
            alert("Unable to save. Please try again later.");
        }
    }

    render() {
        return (
            <section className="section">
                <div className="container is-fixed is-top">
                    <h1 className="title">Chat</h1>
                    <h2 className="subtitle">A simple app for sharing messages.</h2>

                    <nav className="level is-mobile">
                        <div className="level-left">
                            <p className="level-item">
                                <button className="button is-light" onClick={() => this.login()}>Log in</button>
                            </p>
                        </div>
                    </nav>
                </div>

                <div className="container is-fixed is-bottom">
                    <form onSubmit={this.onSubmit}>

                        <div className="field">
                            <div className="field-label is-normal">
                                <label className="label">Message</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <textarea
                                            className="textarea is-primary"
                                            placeholder="Type your message here"
                                            onChange={this.onChange}
                                            onPaste={this.onPaste}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <div className="field-label">

                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input
                                            type="submit"
                                            className="button is-primary"
                                            value="Send message"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="container is-fixed is-middle">
                    {this.state.posts.map((post, i) => (
                        <div className="card" key={i}>
                            <div className="card-content">
                                <div className="content">
                                    <strong>{post.author}</strong>
                                    &nbsp;
                                    <Timestamp stamp={post.timestamp} />
                                    <br />
                                    <p className="multiline">{post.message}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
    );
    }
}

const root = createRoot( document.getElementById("chat") );
root.render(<Chat />);